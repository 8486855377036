import React from "react"

const Error404 = ({ ...props }) => {

	// render html
	return (
		<div>
			<h1>Aww not found...</h1>
		</div>

	)
}

export default Error404;